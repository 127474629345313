<template>
    <div>
       <normal-router :Platform='Platform'></normal-router>
    </div>
</template>
    
<script>
const NormalRouter = resolve=> require.ensure([], () => resolve(require('./RouterTheme/ResNormalRouter.vue')))
// const KuodiRouter = resolve=> require.ensure([], () => resolve(require('./RouterTheme/ResKuodiRouter.vue')))
// const HuLianRouter = resolve=> require.ensure([], () => resolve(require('../market/MarketRouter.vue')))
export default {
    props: ['Platform'],
    components: {
        NormalRouter, 
        // KuodiRouter,
        // HuLianRouter
    },
    data () {
        return {
            currentRouter: 'normal-router',
            account: this.loadAccount(),
            isPhone:''
        };
    },
    watch: {
    },
    methods: {
    },
    created(){
    },
    mounted() {
        let adapter = this.getSessionVal('adapter')
        this.isPhone = (this.isValid(adapter) && adapter != '' && adapter == 'sunphone') ? true : false
        if(this.isPhone) {
            let $meta = $('meta'), len = $meta.length
            $meta.eq(len - 1).prop('content', 'width=device-width,initial-scale=1, user-scalable=no')
        }
    }
}
</script>
    
<style lang="css" scoped>
    .res {
        min-height: 100vh;
    }
</style>
